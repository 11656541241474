@import "mixins";
@import "variables";
@import "fonts";

html,
body {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    background-color: $white;
}

body {
    @include font-size(16px);

    color: $black;
    font-weight: 400;
    font-family: $font-base;
    line-height: 1;
}

:root {
    font-variant-ligatures: none;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

*,
a,
button {
    margin: 0;
    padding: 0;
    outline: none;
    font-feature-settings: "locl" 1;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

button {
    border: 0;
    background-color: transparent;
    font-weight: 400;
    font-family: $font-base, sans-serif;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 700;
    font-family: $font-base, sans-serif;
}

ol,
ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

p {
    margin: 0;
}

strong {
    font-weight: 500;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 30px;
}

header {
    padding: 30px 0;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            display: block;
            width: 40px;
            height: 35px;
        }

        span {
            @include font-size(24px);

            padding: 0 0 0 10px;
            font-weight: 700;
            line-height: 1.6;
        }
    }

    .languages {
        button {
            @include font-size(16px);

            display: none;
            padding: 10px 15px;
            border: 0;
            border-radius: 4px;
            background-color: $blue;
            color: $white;
            font-weight: 500;
            text-transform: uppercase;
            transition: background-color 200ms linear;

            &:hover {
                background-color: darken($blue, 10%);
            }

            &.visible {
                display: block;
            }

            &.hidden {
                display: none;
            }
        }
    }
}

.main-container {
    display: none;

    &.visible {
        display: block;
    }

    &.hidden {
        display: none;
    }
}

footer {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 30px 0;
    }

    a,
    img {
        display: block;
        width: 100px;
        height: 20px;
    }

    p {
        @include font-size(14px);

        padding: 10px 0 0;
        color: $black;
        font-weight: 500;
        text-align: center;
    }
}

// Responsive

// @include screen-xl {}

// @include screen-lg {}

// @include screen-md {}

// @include screen-sm {}

@include screen-xs {
    body {
        font-size: xs(16px);
    }

    .container {
        padding: 0 xs(30px);
    }

    header {
        padding: xs(30px) 0;

        .logo {
            img {
                width: xs(40px);
                height: xs(35px);
            }

            span {
                padding: 0 0 0 xs(10px);
                font-size: xs(24px);
            }
        }

        .languages {
            button {
                padding: xs(10px) xs(15px);
                border-radius: xs(4px);
                font-size: xs(16px);
            }
        }
    }

    footer {
        .wrapper {
            padding: xs(30px) 0;
        }

        a,
        img {
            width: xs(100px);
            height: xs(20px);
        }

        p {
            padding: xs(10px) 0 0;
            font-size: xs(14px);
        }
    }
}
