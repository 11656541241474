@font-face {
    font-weight: 300;
    font-style: normal;
    font-family: "Sofia Sans";
    src: url("../fonts/SofiaSans/SofiaSans-Light.eot");
    src:
        url("../fonts/SofiaSans/SofiaSans-Light.eot#iefix")
        format("embedded-opentype"),
        url("../fonts/SofiaSans/SofiaSans-Light.woff2") format("woff2"),
        url("../fonts/SofiaSans/SofiaSans-Light.woff") format("woff"),
        url("../fonts/SofiaSans/SofiaSans-Light.ttf") format("truetype"),
        url("../fonts/SofiaSans/SofiaSans-Light.svg#SofiaSans-Light")
        format("svg");
    font-display: swap;
}

@font-face {
    font-weight: 400;
    font-style: normal;
    font-family: "Sofia Sans";
    src: url("../fonts/SofiaSans/SofiaSans-Regular.eot");
    src:
        url("../fonts/SofiaSans/SofiaSans-Regular.eot#iefix")
        format("embedded-opentype"),
        url("../fonts/SofiaSans/SofiaSans-Regular.woff2") format("woff2"),
        url("../fonts/SofiaSans/SofiaSans-Regular.woff") format("woff"),
        url("../fonts/SofiaSans/SofiaSans-Regular.ttf") format("truetype"),
        url("../fonts/SofiaSans/SofiaSans-Regular.svg#SofiaSans-Regular")
        format("svg");
    font-display: swap;
}

@font-face {
    font-weight: 500;
    font-style: normal;
    font-family: "Sofia Sans";
    src: url("../fonts/SofiaSans/SofiaSans-Medium.eot");
    src:
        url("../fonts/SofiaSans/SofiaSans-Medium.eot#iefix")
        format("embedded-opentype"),
        url("../fonts/SofiaSans/SofiaSans-Medium.woff2") format("woff2"),
        url("../fonts/SofiaSans/SofiaSans-Medium.woff") format("woff"),
        url("../fonts/SofiaSans/SofiaSans-Medium.ttf") format("truetype"),
        url("../fonts/SofiaSans/SofiaSans-Medium.svg#SofiaSans-Medium")
        format("svg");
    font-display: swap;
}

@font-face {
    font-weight: 600;
    font-style: normal;
    font-family: "Sofia Sans";
    src: url("../fonts/SofiaSans/SofiaSans-SemiBold.eot");
    src:
        url("../fonts/SofiaSans/SofiaSans-SemiBold.eot#iefix")
        format("embedded-opentype"),
        url("../fonts/SofiaSans/SofiaSans-SemiBold.woff2") format("woff2"),
        url("../fonts/SofiaSans/SofiaSans-SemiBold.woff") format("woff"),
        url("../fonts/SofiaSans/SofiaSans-SemiBold.ttf") format("truetype"),
        url("../fonts/SofiaSans/SofiaSans-SemiBold.svg#SofiaSans-SemiBold")
        format("svg");
    font-display: swap;
}

@font-face {
    font-weight: 700;
    font-style: normal;
    font-family: "Sofia Sans";
    src: url("../fonts/SofiaSans/SofiaSans-Bold.eot");
    src:
        url("../fonts/SofiaSans/SofiaSans-Bold.eot#iefix")
        format("embedded-opentype"),
        url("../fonts/SofiaSans/SofiaSans-Bold.woff2") format("woff2"),
        url("../fonts/SofiaSans/SofiaSans-Bold.woff") format("woff"),
        url("../fonts/SofiaSans/SofiaSans-Bold.ttf") format("truetype"),
        url("../fonts/SofiaSans/SofiaSans-Bold.svg#SofiaSans-Bold")
        format("svg");
    font-display: swap;
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "icomoon";
    src: url("../fonts/IcoMoon/icomoon.eot");
    src:
        url("../fonts/IcoMoon/icomoon.eot#iefix") format("embedded-opentype"),
        url("../fonts/IcoMoon/icomoon.ttf") format("truetype"),
        url("../fonts/IcoMoon/icomoon.woff") format("woff"),
        url("../fonts/IcoMoon/icomoon.svg#icomoon") format("svg");
    font-display: block;
}
