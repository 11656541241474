@import "../app";

.page-suspended {
    a {
        font-weight: 600;
        text-decoration: underline;
    }

    .heading {
        padding: 60px 0;
        background-color: $red-3;

        h1,
        p {
            color: $white;
        }

        h1 {
            @include font-size(50px);

            padding: 0 0 10px;
        }

        p {
            @include font-size(22px);

            line-height: 1.4;
        }
    }
}

// Responsive

// @include screen-xl {}

// @include screen-lg {}

// @include screen-md {}

// @include screen-sm {}

@include screen-xs {
    .page-suspended {
        .heading {
            padding: xs(60px) 0;

            h1 {
                padding: 0 0 xs(20px);
                font-size: xs(50px);
            }

            p {
                font-size: xs(24px);
            }
        }
    }
}
