// Fonts

$font-base: "Sofia Sans", sans-serif;

// Colors

$white: #FFF;
$black: #000;

$top-section-1: #C0E5F4;
$top-section-1-darken: #2C93BC;
$top-section-1-button: #094B65;
$top-section-2: #DEEBFF;
$top-section-2-darken: #3371D1;
$top-section-2-button: #174284;
$top-section-2-table-even: #F2F7FF;
$top-section-3: #ECE0FA;
$top-section-3-darken: #7C53AD;
$top-section-3-button: #45246C;
$top-section-3-table-even: #F7F3FD;
$top-section-4: #D8EACC;
$top-section-4-darken: #639741;
$top-section-4-button: #28510D;
$top-section-4-table-even: #EBF3E6;
$top-section-5: #DEE3FF;
$top-section-5-darken: #5669D8;
$top-section-5-button: #2E3D92;
$top-section-5-table-even: #F2F4FF;
$top-section-table-odd: #FFF;

$gray: #262626;
$gray-2: #D5D5D5;
$gray-3: #242424;
$gray-4: #F6F6F4;
$gray-5: #979797;
$gray-6: #F6F3F7;
$gray-7: #CCC;
$gray-8: #D3D3D3;
$gray-9: #B7B7B7;
$gray-10: #D8D8D8;
$gray-11: #6C6C6C;
$gray-12: #FDFDFD;
$gray-13: #F7F7F7;
$gray-14: #EEE;

$blue: #3B64D8;
$blue-2: #2E5AD6;
$blue-3: #2278E9;

$green: #EAF9E2;
$green-2: #F7FBF4;
$green-3: #4C7630;
$green-4: #9DB942;
$green-5: #059669;

$cyan: #26CDCA;

$pink: #F52D7B;

$violet: #BD9CE4;

$orange: #FEFAEF;

$red: #ED2858;
$red-2: #FEF7F9;
$red-3: #E11D48;

$badge-1: #F52D7B;
$badge-2: #26CDCA;

$table-even: #FAFAF8;

$footer-bg: #231F20;
$footer-lines: #D8D8D8;

$socials-facebook: #3B5998;
$socials-twitter: #1DA1F2;
$socials-linkedin: #0A66C2;
