@use "sass:math";

// Screen media queries

// Extra large devices
@mixin screen-xl {
    @media only screen and (max-width: 1440px) {
        @content;
    }
}

@function xl($target) {
    $context: (1440 * 0.01) * 1px;

    @return math.div($target, $context) * 1vw;
}

// Large devices
@mixin screen-lg {
    @media only screen and (max-width: 1366px) {
        @content;
    }
}

@function lg($target) {
    $context: (1366 * 0.01) * 1px;

    @return math.div($target, $context) * 1vw;
}

// Medium devices
@mixin screen-md {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@function md($target) {
    $context: (1280 * 0.01) * 1px;

    @return math.div($target, $context) * 1vw;
}

// Small devices
@mixin screen-sm {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@function sm($target) {
    $context: (1024 * 0.01) * 1px;

    @return math.div($target, $context) * 1vw;
}

// Extra small devices
@mixin screen-xs {
    @media only screen and (max-width: 640px) {
        @content;
    }
}

@function xs($target) {
    $context: (640 * 0.01) * 1px;

    @return math.div($target, $context) * 1vw;
}

// Rem for font-size

@function calculateRem($size) {
    $rem: math.div($size, 16px);

    @return $rem * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}
